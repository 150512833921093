import React from 'react';
import Hero from './Hero/Hero';
import About from './About';
import Works from './Works/Works';
import Contact from './Contact';
import Carosel from './Carousel';
import Footer from './Footer';

const Home: React.FC<{}> = () => {
  return (
    <div className=" overflow-hidden">
      <Hero />
      <Carosel />
      <Works />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;

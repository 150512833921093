import React from 'react';

const Contact: React.FC<{}> = () => {
  const inputFieldClassNames =
    'border-[1px] border-slate-500 border-opacity-50 bg-secondary-lifeblood-99 rounded-lg py-3 px-5 w-full';

  return (
    <div
      className=" 
    flex items-center justify-center 
    pt-24 pb-32 px-5 2xs:px-10
    bg-zinc-100 bg-fabric [box-shadow:_inset_0_0_100px_hsla(0,0%,0%,0.25)]"
      id="contact"
    >
      <div className=" flex flex-col items-center gap-2 w-full h-full max-w-xs ml:max-w-md">
        <div className=" flex justify-center w-full pb-5">
          <h1 className="font-header-sofia-sans-condensed text-4xl ml:text-5xl text-slate-700">
            Get in touch!
          </h1>
        </div>
        <form
          id="fs-frm"
          name="simple-contact-form"
          acceptCharset="utf-8"
          action="https://formspree.io/f/xoqzdyyg"
          method="post"
          className="flex flex-col items-start gap-2 w-full "
        >
          <fieldset
            id="fs-frm-inputs"
            className="flex flex-col gap-2 items-center w-full"
          >
            <input
              type="text"
              name="Sender's name"
              id="full-name"
              placeholder="Name..."
              required
              className=" border-[1px] border-slate-500 border-opacity-50 bg-secondary-lifeblood-99 rounded-lg py-3 px-5 w-full"
            />
            <input
              type="email"
              name="Sender's email"
              id="email-address"
              placeholder="Email address..."
              required
              className={inputFieldClassNames}
            />
            <textarea
              rows={5}
              name="Message"
              id="message"
              placeholder="Message..."
              required
              className={inputFieldClassNames}
            ></textarea>
            <input
              type="hidden"
              name="_subject"
              id="email-subject"
              value="Contact Form Submission"
              className={inputFieldClassNames}
            />
          </fieldset>
          <div className="flex justify-end w-full">
            <button
              type="submit"
              className="border-1 bg-zinc-900 hover:bg-zinc-800 active:bg-gray-700 rounded-lg py-3 px-5 text-zinc-50"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;

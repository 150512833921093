import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import PlayIcon from './PlayIcon';
import trashdayThumbnail from '../../../assets/img/trashday_thumbnail.webp';

const ShowcaseItem: React.FC<{}> = () => {
  const [overlayIsVisible, setOverlayIsVisible] = useState<boolean>(true);
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [vimeoPlayer, setVimeoPlayer] = useState<Player>();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    setVimeoPlayer(new Player(videoRef.current));
  }, []);

  const clickListener = () => {
    if (!overlayIsVisible) {
      return;
    }

    setOverlayIsVisible(false);

    if (!vimeoPlayer) {
      return;
    }

    vimeoPlayer.play();
  };

  const title = 'Trash Day: Dumpster Fire';

  return (
    <div className=" flex flex-col gap-2 w-full">
      {/* Video container */}
      <div className="overflow-hidden">
        <div className=" relative aspect-[18/13] w-full rounded-xl group">
          {/* Image overlay */}
          <img
            src={trashdayThumbnail}
            alt=""
            className={`absolute top-0 left-0 
              [filter:grayscale(100%)] group-hover:filter-none transition-all ease-in-out rounded-xl
              ${overlayIsVisible ? '' : 'hidden'}
              cursor-pointer`}
            onClick={clickListener}
          />

          {/* Play icon */}
          <PlayIcon
            isVisible={overlayIsVisible}
            clickListener={clickListener}
          />

          {/* Video */}
          <div
            className={`p-[72.87%_0_0_0] relative w-full ${
              overlayIsVisible ? '-z-10' : 'z-10'
            }`}
          >
            <iframe
              src="https://player.vimeo.com/video/799751735?h=8f49f46757&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              className=" absolute top-0 left-0 w-full h-full"
              title="Trash Day - Dumpster Fire.mp4"
              ref={videoRef}
            ></iframe>
          </div>
        </div>
      </div>

      {/* Label */}
      <div className="flex justify-center xs:justify-end w-full">
        <h1
          className="
      font-header-sofia-sans-condensed text-black
      font-[200] 
      text-2xl sm:text-3xl"
        >
          {title}
        </h1>
      </div>
    </div>
  );
};

// ({
//   /* <div className=" grid grid-rows-1 grid-cols-3 min-h-[50vh]">

//       <div
//         className="
//         row-start-1 col-start-1 relative
//         flex flex-col gap-4 p-16 mx-auto
//         max-w-3xl h-full
//         text-white overflow-hidden"
//       >
//         <img
//           src={trashdayThumbnail}
//           alt=""
//           className="
//           absolute top-0 left-0
//           h-full min-w-fit aspect-[18/13] -z-10"
//         />
//         <h1
//           className="
//       font-header-sofia-sans font-black text-4xl"
//         >
//           {title}
//         </h1>
//         <p
//           className="
//       font-paragraph-fira-sans font-light text-lg"
//         >
//           {content}
//         </p>
//       </div>

//       <div
//         className="
//         col-start-2 col-span-2
//         flex justify-center items-center h-full py-24
//         text-[hsla(241,15%,15%,1)] bg-neutral-400 "
//       >

//         <div className="  max-w-[56rem] max-h-[37.33rem] w-[50vw] [box-shadow:0_0_40px_#6c0857] ">
//           <div className=" p-[72.87%_0_0_0] relative w-full">
//             <iframe
//               src="https://player.vimeo.com/video/799751735?h=8f49f46757&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
//               frameBorder="0"
//               allow="autoplay; fullscreen; picture-in-picture"
//               allowFullScreen
//               className=" absolute top-0 left-0 w-full h-full"
//               title="Trash Day - Dumpster Fire.mp4"
//             ></iframe>
//           </div>
//         </div>
//       </div>
//     </div> */
// });

export default ShowcaseItem;

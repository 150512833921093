import React from 'react';

const PlayIcon: React.FC<{
  isVisible: boolean;
  clickListener: { (): void };
}> = ({ isVisible, clickListener }) => {
  return (
    <div
      className={`absolute z-20 left-0 bottom-0
  flex justify-center items-center
aspect-square py-4 px-6
 transition-all ease-in-out
${isVisible ? '' : 'hidden'}
cursor-pointer`}
      onClick={clickListener}
    >
      <i
        className={`fa-solid fa-play text-white shadow-2xl group-hover:text-yellow-500 text-4xl [text-shadow:0_0_200px_#fff]`}
      ></i>
    </div>
  );
};

export default PlayIcon;

import React, { useEffect, useRef, useState } from 'react';
import brandon from '../../assets/img/brandon-cam-work.webp';

const About: React.FC<{}> = () => {
  const aboutTitle = 'About';
  const aboutContent =
    'From producing award winning independent short films, editing content for brands, and shooting events, Brandon is a passionate storyteller in multiple mediums. Bringing a vibrant energy and daring perspective to every project, he works to provide clients high grade professional level content. He is currently located in Santa Cruz, California, but is willing to work anywhere from Sacramento all the way down to Los Angeles.';

  const imgContainerRef = useRef<HTMLDivElement>(null);
  const brandonImgRef = useRef<HTMLImageElement>(null);
  const topDisplacementLocalStorageKey = useRef<string>('topDisplacement');
  const [brandonImgTop, setBrandonImgTop] = useState<number>(
    getTopDisplacementFromLocalStorage()
  );

  function getTopDisplacementFromLocalStorage() {
    const displacement = window.localStorage.getItem(
      topDisplacementLocalStorageKey.current
    );
    if (!displacement) {
      return 0;
    }

    return Number.parseInt(displacement);
  }

  function getTopDisplacementForImg(): number {
    if (!imgContainerRef.current) {
      return 0;
    }

    if (!brandonImgRef.current) {
      return 0;
    }

    const windowHeight = window.innerHeight;
    const containerHeight = imgContainerRef.current.clientHeight;
    const containerTop = imgContainerRef.current.getBoundingClientRect().top;

    const totalTravelDistance = windowHeight + containerHeight;
    const trueZeroPoint = -containerHeight;
    const distanceFromZero = containerTop - trueZeroPoint;

    const percentageTravelledByTop = distanceFromZero / totalTravelDistance;

    const imgElementHeight = brandonImgRef.current.clientHeight;
    const heightDifference = Math.abs(containerHeight - imgElementHeight);

    const topDisplacement = -(
      heightDifference *
      (1 - percentageTravelledByTop)
    );

    return topDisplacement;
  }

  useEffect(() => {
    function parallaxEffect() {
      const topDisplacement = getTopDisplacementForImg();
      window.localStorage.setItem(
        topDisplacementLocalStorageKey.current,
        topDisplacement.toString()
      );
      setBrandonImgTop(getTopDisplacementForImg());
    }

    document.addEventListener('scroll', parallaxEffect);

    // return () => {
    //   document.removeEventListener('scroll', parallaxEffect);
    //   window.localStorage.removeItem(topDisplacementLocalStorageKey.current);
    // };
  }, []);

  return (
    <div
      id="about"
      className=" bg-zinc-800  [box-shadow:_inset_0_0_500px_hsla(0,0%,0%,0.5)] bg-lines py-24 lg:py-56 text-[hsla(220,50%,87%,1)]"
    >
      <div
        ref={imgContainerRef}
        className=" 
        relative max-md:h-60 max-md:w-screen
        max-md:flex max-md:flex-col max-md:justify-end max-md:items-center 
        ml:h-screen 
        ml:mx-auto 
        md:p-16 lg:px-24 xl:py-28 
        md:bg-zinc-600 md:hover:bg-[#202e7e]
        max-w-[1200px] max-h-[800px] aspect-[15/11] overflow-hidden
        group transition-all"
      >
        {/* About content, large screens */}
        <div
          className=" 
        flex flex-col gap-6 
        relative max-md:hidden z-10 
        max-w-[55%] ml:max-w-[400px] 
        p-8 lg:px-10 lg:py-12 rounded-md
        bg-zinc-900 group-hover:bg-[rgba(13,18,46,0.87)] transition-all bg-opacity-[0.95]
        text-[hsla(220,50%,87%,1)] "
        >
          <h1 className=" font-header-sofia-sans font-extralight text-5xl">
            {aboutTitle}
          </h1>
          <p className="font-paragraph-fira-sans font-light">{aboutContent}</p>
        </div>

        {/* Image */}
        <img
          src={brandon}
          ref={brandonImgRef}
          alt=""
          className=" absolute left-1/2 object-cover [transform:translate(-55%)] h-[170%] w-[170%] max-w-[170%] md:[filter:grayscale(100%)] md:mix-blend-screen "
          style={{ top: brandonImgTop }}
        />

        {/* Title, small screens */}
        <h1
          className=" 
        pb-1 
         text-6xl font-header-sofia-sans-condensed font-extralight md:hidden z-10"
        >
          {aboutTitle}
        </h1>
      </div>

      {/* About content, small screens */}
      <p className=" md:hidden px-7 xs:px-16 sm:px-24 pt-6 font-paragraph-fira-sans font-light text-lg  leading-7">
        {aboutContent}
      </p>
    </div>
  );
};

export default About;

import React from 'react';

const Footer: React.FC<{}> = () => {
  return (
    <div
      className=" 
    flex justify-center items-center w-full min-h-[110px] 
    bg-zinc-900 bg-lines text-zinc-500"
    >
      <div className="flex flex-col justify-center items-center">
        <h3>© 2023 Brandon Victoriano</h3>
        <h3>
          Website created by{' '}
          <a
            href="https://www.linkedin.com/in/gamliel-urlanda/"
            target="_blank"
            rel="noreferrer"
            className=" underline underline-offset-2"
          >
            Gam Urlanda{' '}
            <i className=" fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </h3>
      </div>
    </div>
  );
};

export default Footer;

import React, { useRef, useState } from 'react';

const Navbar: React.FC<{}> = () => {
  const ulElement = useRef<HTMLUListElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <header>
      <button
        aria-controls="primary-navigation"
        aria-expanded="false"
        className={`absolute top-8 right-8 z-[9999] w-10 aspect-square fa-solid ${
          isVisible ? 'fa-xmark' : 'fa-bars'
        } text-2xl text-white [text-shadow:0_0_30px_hsl(0,0%,0%,1)] ms:hidden`}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        <span className="sr-only">Menu</span>
      </button>
      <nav>
        <ul
          id="primary-navigation"
          ref={ulElement}
          className={`
          flex flex-col 
  fixed top-0 right-0 z-[9998] gap-5
  min-h-screen pl-7 pr-40 py-[min(20vh,5rem)]  
  bg-[hsl(0,0%,20%,0.1)] backdrop-blur-[16px] 
  font-paragraph-fira-sans font-light text-white text-lg 
  transition-transform ${
    isVisible ? 'max-ms:translate-x-[0%]' : 'max-ms:translate-x-full'
  } 
  
  ms:static ms:flex-row ms:justify-end ms:gap-20 ms:items-center 
  ms:px-7 ms:pt-5 ms:pb-10 ms:min-h-[auto]
  ms:backdrop-blur-none ms:bg-transparent 
  ms:bg-gradient-to-b ms:from-[hsla(0,0%,0%,0.4)] ms:to-transparent 
  `}
        >
          <li onClick={() => setIsVisible(false)}>
            <a href="#featured-work">Works</a>
          </li>
          <li onClick={() => setIsVisible(false)}>
            <a href="#contact">Contact</a>
          </li>
          <li onClick={() => setIsVisible(false)}>
            <a href="#about">About</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;

import React from 'react';
import heroVideo from '../../../assets/vid/hero-nobars-noaudio.mp4';
import Navbar from './Navbar';

const Hero: React.FC<{}> = () => {
  return (
    <div className="relative min-w-screen min-h-screen">
      <Navbar />
      <video
        className=" min-h-screen min-w-[100vw] aspect-[16/9] max-w-none absolute left-1/2 top-1/2 [transform:translate(-50%,-50%)] -z-10"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={heroVideo} type="video/mp4" />
      </video>
      <div className="absolute bottom-[10%] w-full px-12 flex flex-col items-center gap-2 font-name-ibm-plex-serif text-white text-center leading-none transition-opacity [text-shadow:0_0_20px_hsl(0,0%,0%,0.75)]">
        <h1 className="text-[clamp(3.5rem,_2.9vw_+_3rem,_5.75rem);]">
          Brandon Victoriano
        </h1>
        <h2 className="text-[clamp(2.25rem,_1.9vw_+_1.9rem,_3.75rem);]">
          Director
        </h2>
      </div>
      {/* <div className="relative h-screen w-[min(90vw,100%)]">
      </div> */}
    </div>
  );
};

export default Hero;

import React from 'react';
import Featured from './Featured';
import OtherWorks from './OtherWorks';

const Works: React.FC<{}> = () => {
  return (
    <>
      <Featured />
      <OtherWorks />
    </>
  );
};

export default Works;

import React from 'react';
import JPItem from './JPItem';
import TrashDayItem from './TrashDayItem';

const OtherWorks: React.FC<{}> = () => {
  return (
    <div
      className=" 
    flex flex-col justify-center items-center 
    pt-12 xs:pt-16 pb-14 xs:pb-16 sm:pb-20 px-10 sm:px-32 lg:px-40 lg:pt-32 lg:pb-48
    [box-shadow:_inset_0_0_500px_hsla(0,0%,0%,0.2)] bg-rice-paper bg-fixed bg-yellow-100"
    >
      <div className="flex flex-col justify-center items-center h-full w-full max-md:max-w-[400px]">
        <div className="flex justify-center w-full mb-4 ms:mb-10 ">
          <h1
            className="
      font-header-sofia-sans-condensed text-slate-800
      font-light
      text-4xl sm:text-5xl lg:text-7xl"
          >
            More Featured Works
          </h1>
        </div>

        <div
          className=" 
          flex flex-col gap-10
          md:grid md:grid-cols-2
          w-full lg:gap-16"
        >
          <div className=" md:col-start-1">
            <JPItem />
          </div>
          <div className=" md:col-start-2">
            <TrashDayItem />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherWorks;

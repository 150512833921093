import React from 'react';
import laurelsFiles from '../../../assets/img/laurels/laurelsExports';
// import popStill from '../../../assets/img/POP_3_SG.webp';
import popTitle from '../../../assets/img/pop_title.webp';

const Featured: React.FC<{}> = () => {
  // const header = 'POP';
  const content = '.';

  const firstTwoRowsLaurels: string[] = [];
  for (let i = 0; i < 6; i++) {
    firstTwoRowsLaurels.push(laurelsFiles[i]);
  }

  const lastRowLaurels: string[] = [laurelsFiles[6], laurelsFiles[7]];

  return (
    <div
      className=" relative
      flex flex-col justify-center items-center gap-12
      py-20 pb-32 min-h-screen
      text-white 
      bg-pop-still bg-cover bg-no-repeat bg-center bg-fixed-hack"
    >
      {/* Text content */}
      <div
        className="flex flex-col gap-4 
      px-7 xs:px-16 sm:px-24 mx-auto max-w-3xl "
      >
        <div className="flex justify-center w-full">
          <h1
            className="
            font-header-sofia-sans-condensed font-[100] text-4xl"
            id="featured-work"
          >
            Showcased Work
          </h1>
        </div>
        <div
          className="
        flex flex-col items-center
        ml:flex-row ml:items-end ml:gap-5
        mx-auto"
        >
          <img
            src={popTitle}
            alt=""
            className=" h-48 object-contain [filter:invert(1)] max-ml:pb-4"
          />
          <div className="flex flex-col justify-end items-center ml:items-start">
            <h2 className=" font-header-sofia-sans-condensed font-[100] text-3xl ml:text-5xl">
              Short film
            </h2>
            <h3 className=" text-2xl font-header-sofia-sans-condensed font-light ">
              Director, Writer,
              <br className="max-2xs:hidden" /> Producer, Editor
            </h3>
            {/* <div className="max-2xs:hidden text-2xl font-header-sofia-sans-condensed font-light">
              <h3>Director, Writer,</h3>
              <h3>Producer, Editor</h3>
            </div> */}
          </div>
        </div>

        {/* Video */}
        <div className=" ml:hidden pb-5 max-w-[56rem] max-h-[31.5rem] w-full">
          <div className="p-[56.25%_0_0_0] relative w-full">
            <iframe
              src="https://player.vimeo.com/video/723200032?h=8046450a07&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              className=" absolute top-0 left-0 w-full h-full"
              title="POP - Short Film"
            ></iframe>
          </div>
        </div>

        {/* Laurels */}
        <div className="">
          <div className=" grid grid-cols-3 gap-1">
            {firstTwoRowsLaurels.map((element, index) => (
              <div
                className="relative flex items-center justify-center aspect-[2.5/2]"
                key={index}
              >
                <img
                  src={element}
                  alt=""
                  className=" w-full object-contain [filter:invert(1)_grayscale(100%)_drop-shadow(0_0_10px_black)]"
                />
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 gap-1">
            {lastRowLaurels.map((element, index) => (
              <div
                className="relative flex items-center justify-center aspect-[2.5/2]"
                key={index}
              >
                <img
                  src={element}
                  alt=""
                  className=" h-full object-contain [filter:invert(1)_grayscale(100%)_drop-shadow(0_0_10px_black)]"
                />
              </div>
            ))}
          </div>
        </div>

        <p
          className="
      hidden
      font-paragraph-fira-sans font-light text-l shadow-[0_0_30px_black] bg-[hsla(0,0%,0%,0.3)]"
        >
          {content}
        </p>
      </div>

      {/* Video */}
      <div className=" max-ml:hidden max-w-[56rem] max-h-[31.5rem] w-full">
        <div className="p-[56.25%_0_0_0] relative w-full">
          <iframe
            src="https://player.vimeo.com/video/723200032?h=8046450a07&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            className=" absolute top-0 left-0 w-full h-full"
            title="POP - Short Film"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Featured;

import still_0 from './still-0.webp';
import still_1 from './still-1.webp';
import still_2 from './still-2.webp';
import still_3 from './still-3.webp';
import still_4 from './still-4.webp';
import still_5 from './still-5.webp';
import still_6 from './still-6.webp';
import still_7 from './still-7.webp';
import still_8 from './still-8.webp';
import still_9 from './still-9.webp';
import still_10 from './still-10.webp';
import still_11 from './still-11.webp';
import still_12 from './still-12.webp';
import still_13 from './still-13.webp';
import still_14 from './still-14.webp';
import still_15 from './still-15.webp';

const asArray = [
  still_0,
  still_1,
  still_2,
  still_3,
  still_4,
  still_5,
  still_6,
  still_7,
  still_9,
  still_8,
  still_10,
  still_11,
  still_12,
  still_13,
  still_14,
  still_15,
];

export default asArray;

import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import PlayIcon from './PlayIcon';
import jpThumbnail from '../../../assets/img/jp_thumbnail.webp';

const ShowcaseItem: React.FC<{}> = () => {
  const [overlayIsVisible, setOverlayIsVisible] = useState<boolean>(true);
  const videoRef = useRef<HTMLIFrameElement>(null);
  const [vimeoPlayer, setVimeoPlayer] = useState<Player>();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    setVimeoPlayer(new Player(videoRef.current));
  }, []);

  const clickListener = () => {
    if (!overlayIsVisible) {
      return;
    }

    setOverlayIsVisible(false);

    if (!vimeoPlayer) {
      return;
    }

    vimeoPlayer.play();
  };

  const title = "don't shoot: Independence Day";

  return (
    <div className="flex flex-col gap-2 ">
      {/* Video container */}
      <div className="overflow-hidden" onClick={clickListener}>
        <div className="relative aspect-[18/13] w-full rounded-xl group">
          {/* Image overlay */}
          <img
            src={jpThumbnail}
            alt=""
            className={`absolute top-0 left-0 [filter:grayscale(100%)] group-hover:filter-none transition-all ease-in-out rounded-xl cursor-pointer ${
              overlayIsVisible ? '' : 'hidden'
            }`}
          />

          {/* Play button */}
          <PlayIcon
            isVisible={overlayIsVisible}
            clickListener={clickListener}
          />

          {/* Video */}
          <div
            className={`p-[72.87%_0_0_0] relative w-full ${
              overlayIsVisible ? '-z-10' : 'z-10'
            }`}
          >
            <iframe
              src="https://player.vimeo.com/video/802322835?h=c800d66871&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              className=" absolute top-0 left-0 w-full h-full"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Just Perspective - don&amp;#039;t shoot | Independence Day"
              ref={videoRef}
            ></iframe>
          </div>
        </div>
      </div>

      {/* Label */}
      <div className="flex justify-center xs:justify-end w-full">
        <h1
          className="
      font-header-sofia-sans-condensed text-black
      font-[200] 
      text-2xl sm:text-3xl"
        >
          {title}
        </h1>
      </div>
    </div>
  );
};

export default ShowcaseItem;

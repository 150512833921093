import React from 'react';
import stills from '../../assets/img/stills/stillsExports';

const Carosel: React.FC<{}> = () => {
  const carouselImgs = [...stills, ...stills, stills[0]];
  return (
    <div
      className=" 
     min-h-[80vh] flex items-center
    bg-lines bg-slate-600 bg-fixed [box-shadow:_inset_0_0_500px_hsla(0,0%,0%,0.5)] sm:py-24 xl:pt-16 xl:pb-24"
    >
      <div
        className="flex flex-col items-center justify-center gap-10
    w-screen overflow-hidden "
      >
        <div className=" flex h-1/2 gap-0 full-slide">
          {carouselImgs.map((imgPath, index) => (
            <img
              src={imgPath}
              alt=""
              className=" object-cover aspect-[3/2] min-w-[400px] max-w-[400px] sm:min-w-[600px] sm:max-w-[600px] xl:min-w-[800px] xl:max-w-[800px]"
              key={index}
            />
          ))}
        </div>

        {/* Text content */}
        <div className="flex justify-center">
          <h1
            className=" 
        font-paragraph-fira-sans-condensed font-[100] 
        text-3xl 3xs:text-4xl xl:text-5xl text-center text-slate-300 max-w-[425px] lg:max-w-[500px] xl:max-w-[650px]"
          >
            More than just videos.{' '}
            <span
              className=" font-normal text-slate-100
          text-5xl 3xs:text-6xl xl:text-7xl
          "
            >
              <br />
              Stories.
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Carosel;

// import paris_intl_award_winner from './laurels-0.webp';
import hollywood_tiber from './laurels-1.webp';
import sound_and_vision from './laurels-2.webp';
import sweet_democracy_dark_comedy from './laurels-3.webp';
import moody_crab from './laurels-4.webp';
// import la_film_and_script from './laurels-5.webp';
// import sweet_democracy_young_filmmaker from './laurels-6.webp';
// import night_shorts from './laurels-7.webp';
// import eight_halfilm from './laurels-8.webp';
import horror_night_shorts from './laurels-9.webp';
import naked_mountain from './laurels-10.webp';
// import serbest from './laurels-11.webp';
// import student_world_impact from './laurels-12.webp';
// import paris_intl_semi_finalist from './laurels-13.webp';
// import liftoff from './laurels-14.webp';
import la_feedback from './laurels-15.webp';
import chandler from './laurels-16.webp';

const asArray = [
  naked_mountain,
  chandler,
  horror_night_shorts,
  hollywood_tiber,
  sound_and_vision,
  sweet_democracy_dark_comedy,
  moody_crab,
  la_feedback,
];

// const theOthers = [
//   sweet_democracy_young_filmmaker,
//   la_film_and_script,
//   paris_intl_award_winner,
//   night_shorts,
//   eight_halfilm,
//   serbest,
//   student_world_impact,
//   paris_intl_semi_finalist,
// ];

export default asArray;
